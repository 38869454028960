.App {
  text-align: center;
  background-image: url("./Assets/Img/fondo.jpg");
  background-size: cover;
  background-position: center;
  height: 100vh;
}

.title {
  height: 10%;                                                                    /* +10% de altura */
  display: flex;
  background: rgb(0, 0, 0);
}

@font-face {
  font-family: airstrike;
  src: url('./Assets/Fonts/airstrike.ttf');
}

.title1 {
  clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0% 100%);
  margin: 0 auto 0 0;
  background: rgb(192, 3, 3);
  width: 40%;
  display: flex;
  height: 100%;
}

.title1h1 {
  margin: auto;
  color: white;
  font-size: 5.5vw;
  font-family: airstrike;
}

.title2 {
  margin: 0 0 0 auto;
  width: 50%;
  display: flex;
  height: 100%;
}

.title2h1 {
  font-family: airstrike;
  color: rgb(192, 3, 3);
  margin: auto;
  font-size: 5.5vw;
  font-family: airstrike;
}

.titleSub {
  height: 18%;                                                           /* +18% de altura */
  display: flex;
}

.titleServicio {
  height: 90%;
  background: rgb(0, 0, 0);
  display: flex;
  clip-path: polygon(0% 0%, 100% 0%, 90% 100%, 0% 100%);
  width: 50%;
}

.tool {
  margin: auto 0 auto auto;
  width: 7vw;
}

@font-face {
  font-family: conthrax;
  src: url('./Assets/Fonts/conthrax-sb.otf');
}

.titleServicioh1 {
  font-family: conthrax;
  margin: auto auto auto 0;
  color: rgb(209, 207, 207);
  font-size: 3vw;
  text-decoration: underline;
}

@keyframes destello {

  0%,
  100% {
    filter: brightness(1) drop-shadow(0 0 10px rgba(255, 255, 255, 0.5));
  }

  50% {
    filter: brightness(1.1) drop-shadow(0 0 20px rgba(255, 255, 255, 0.5));
  }
}

.logo {
  margin: auto;
  width: 30vh;
  animation: destello 1.5s infinite ease-in-out;
}

.divServicios {
  height: 32%;                                                           /* +32% de altura */
  display: flex;
}

.sPss {
  margin: auto;
  width: 28vw;
  /*cursor: zoom-in;                                                ORGANIZAR EL ZOOM*/
  transition: filter 0.5s;
}
.sPss:hover {
  filter: brightness(1) drop-shadow(0 0 8px rgba(253, 2, 2, 0.377));
}

.servicios {
  width: 60vw;
  margin: auto auto 0 auto;
}

@font-face {
  font-family: coolvetica;
  src: url('./Assets/Fonts/coolveticarg.otf');
}

.servicio {
  font-size: 2.4vw;
  color: white;
  font-family: coolvetica;
  text-align: left;
}

.divResumenCorreo{
  height: 12%;
  display: flex;
}

@font-face {
  font-family: Hack;
  src: url('./Assets/Fonts/Hack.ttf');
}
.resumen{
  width: 50%;
  height: 100%;
  display: flex;
}

.resumenh3{
  width: 90%;
  margin: auto;
  font-family: Hack;
  font-size: 1.4vw;
  color: red;
}

.correo{
  width: 50%;
  display:flex ;
}

.aGmail{
  margin: auto 1.5vw auto auto;
  width: max-content;
  height: min-content;
}

.gmail{
  margin: 0;
  width: 3.5vw;
  cursor:pointer;
}

.correoh1 {
  font-family: conthrax;
  margin: auto auto auto 0;
  color: rgb(98, 127, 255);
  font-size: 2.5vw;
  text-decoration: underline;
  cursor:pointer;
}
.correoh1:hover{
  color: rgb(25, 67, 252);
}

.contactos {
  height: 18%;                                                           /* +18% de altura */
  display: flex;
}

.contacto1 {
  margin: auto auto 0 auto;
  display: flex;
  width: 50%;
  height: 90%;
  text-decoration: none;
  transition: filter 0.5s;
}
.contacto1:hover {
  filter: brightness(1) drop-shadow(0 0 10px rgb(253, 2, 2));
}

.whatsapp{
  margin: auto  1vw auto auto;
  width: 6vw;
}

.contacto1h3{
  font-family: hack;
  font-size: 4vw;
  margin: auto auto auto 0;
  color: white;
}

.contacto2 {
  margin: auto 0 0 auto;
  height: 90%;
  width: 50%;
  background: rgb(0, 0, 0);
  display: flex;
  clip-path: polygon(10% 0%, 100% 0%, 100% 100%, 0% 100%);
  text-decoration: none;
}
.contacto2:hover {
  .tel{
    filter: brightness(1) drop-shadow(0 0 5px rgb(255, 1, 1));
  }
  .contacto2h3{
    filter: brightness(1) drop-shadow(0 0 5px rgb(255, 0, 0));
  }
}

.tel{
  transition: filter 0.5s;
  margin: auto  1vw auto auto;
  width: 5vw;
}

.contacto2h3{
  font-family: hack;
  font-size: 4vw;
  margin: auto auto auto 0;
  color: white;
  transition: filter 0.5s;
}

.pie {
  height: 10%;                                                                    /* +10% de altura */
  display: flex;
  background: rgb(0, 0, 0);
}

.pie1 {
  clip-path: polygon(10% 0%, 100% 0%, 100% 100%, 0% 100%);
  margin: 0 0 0 auto;
  background: rgb(192, 3, 3);
  width: 40%;
  display: flex;
  height: 100%;
}

.pie1h1 {
  margin: auto;
  color: rgb(251, 255, 0);
  font-size: 5.5vw;
  font-family: airstrike;
}

.pie2 {
  margin: 0 0 0 auto;
  width: 60%;
  display: flex;
  height: 100%;
}

.pie2h1 {
  font-family: airstrike;
  color: rgb(192, 3, 3);
  margin: auto;
  font-size: 5.5vw;
  font-family: airstrike;
}

/*-----------------------------------------------------------------------------*/


@media (max-width: 1920px){
  .sPss {
    width: 25vw;
  }
  .resumenh3{
    width: 98%;
    font-size: 1.3vw;
  }
  .servicios{
    margin: auto;
  }
  .servicio{
    font-size: 2vw;
  }
}

@media (max-width: 1200px) {
  .servicios {
    margin: auto;
  }
  .resumenh3{
    width: 95%;
    font-size: 1.6vw;
  }
  .pie2 {
    width: 40%;
  }
}

@media (max-width: 900px) {
  .divServicios {
    flex-direction: column;
  }
  .sPss {
    width: 37vw;
  }
  .servicios {
    width: 90vw;
    margin: 0 auto;
  }
  .servicio{
    font-size: 2.8vw;
  }
  .divResumenCorreo{
    flex-direction: column;
  }
  .resumen{
    width: 90%;
    margin: auto;
  }
  .correo{
    margin: auto;
  }
  .gmail{
    width: 5vw;
  }
  .correoh1 {
    font-size: 3.2vw;
  }
  .contacto1{
    flex-direction: column;
  }
  .whatsapp{
    margin: auto auto 1vw auto;
    width: 8vw;
  }
  .contacto1h3{
    margin: 1vw auto auto auto;
    font-size: 5vw;
  }
  .contacto2{
    flex-direction: column;
  }
  .tel{
    margin: auto auto 1vw auto;
    width: 8vw;
  }
  .contacto2h3{
    margin: 1vw auto auto auto;
    font-size: 5vw;
  }
}

@media (max-width: 600px) {
  .tool {
    width: 14vw;
  }
  .titleServicioh1 {
    font-size: 5vw;
    width: min-content;
  }
  .logo {
    width: 25vh;
  }
  .resumenh3{
    width: 98%;
    font-size: 2.2vw;
  }
  .whatsapp{
    width: 14vw;
  }
  .tel{
    width: 12vw;
  }
  .pie2 {
    width: 40%;
  }
}

@media (max-width: 500px) {
  .logo {
    width: 20vh;
  }
  .sPss {
    width: 55vw;
  }
  .servicio{
    font-size: 3.5vw;
  }
}

@media (max-width: 400px) {
  .sPss {
    width: 60vw;
  }
  .servicios {
    width: 85vw;
  }
  .servicio{
    font-size: 3.8vw;
  }
  .resumenh3{
    font-size: 2.5vw;
  }
}

@media (max-width: 300px) {
  .logo {
    width: 15vh;
  }
  .sPss {
    width: 55vw;
  }
  .servicios {
    width: 82vw;
  }
  .servicio{
    font-size: 4.6vw;
  }
  .resumenh3{
    font-size: 2.9vw;
  }
}